//@ts-nocheck
import request from "superagent";
import superagent from "superagent";
import React, {useEffect, useState} from "react";
import {ourToast} from "../atoms/Toast";
import Page500 from "../pages/Page500";

type EnvFetcherType = {
  children: JSX.Element,
  onLoad: (val: {
    backend_url: string,
    environment_name: string,
    release_name: string,
    sentry_url?: string,
    sentry_dsn?: string,
  }) => void
}

export function EnvFetcher({children, onLoad}: EnvFetcherType) {
  const [env, setEnv] = useState<string | null>(null);

  useEffect(() => {
    request.get("/configs/env.json").then((resp) => {
      let envJsonString = JSON.stringify(resp.body);
      if (!envJsonString) {
        return <Page500/>
      } else {
        localStorage.setItem('env', envJsonString)
        setEnv(envJsonString)
        if (onLoad) {
          onLoad(resp.body);
        }
      }
    })
  }, [])

  if (env) {
    return children
  }
  return null;
}

export function getOnixUrl(path: string) {
  const env = localStorage.getItem("env")
  if (env != null) {
    return JSON.parse(env)?.backend_url.replace(/\/+$/, '') + path;
  } else {
    return null
  }
}

function makeRequest<R>(method: 'get' | 'post' | 'put' | 'delete', url: string, data?: any): Promise<R> {
  const backendUrl = getOnixUrl(url);
  return new Promise((resolve, reject) => {
    if (backendUrl == null) {
      reject(new Error("No backend url found"));
      return;
    }
    const request = superagent[method](backendUrl);
    if (method === 'post' || method === 'put') {
      request.send(data);
    } else if (data) {
      request.query(data);
    }
    request
      .set('Authorization', "JWT " + localStorage.getItem('jwtKey'))
      .set('Accept', 'application/json')
      .then(response => resolve(response.body))
      .catch((err) => {
        reject(new Error(err.message));
      });
  });
}

const HttpService = {
  GET: function <R>(url: string, query?: Record<string, any>): Promise<R> {
    return makeRequest<R>('get', url, query);
  },
  POST: function <P, R>(url: string, data: P): Promise<R> {
    return makeRequest<R>('post', url, data);
  },
  PUT: function <P, R>(url: string, data: P): Promise<R> {
    return makeRequest<R>('put', url, data);
  },
  DELETE: function <R>(url: string, query?: Record<string, any>): Promise<R> {
    return makeRequest<R>('delete', url, query);
  },
};
export { HttpService };

export function multiPartHttpRequest(url, fields, files, onResponse, onError, method = 'post') {
  let methodHandler = request[method];
  let r = methodHandler(getOnixUrl(url))
    .set('Authorization', "JWT " + localStorage.getItem('jwtKey'));

  for (let fileFieldName of Object.keys(files)) {
    r = r.attach(fileFieldName, files[fileFieldName])
  }
  for (let fieldName of Object.keys(fields)) {
    r = r.field(fieldName, fields[fieldName] || '')
  }

  r.then(resp => {
    let data = resp.body;
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
    onResponse(data);
  }).catch(err => {
    let errorBody;
    try {
      errorBody = JSON.parse(err.response.text);
    } catch (e) {
      onError([{message: '' + e}])
      return;
    }
    if (errorBody.detail) {
      onError([{message: errorBody.detail}]);
    } else if (errorBody.message) {
      onError([{message: errorBody.message}]);
    } else {
      onError([{message: errorBody}]);
    }
  })
}

export function downloadFile(sUrl) {

  let isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  let isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

  //iOS devices do not support downloading. We have to inform user about this.
  if (/(iP)/g.test(navigator.userAgent)) {
    alert('Your device does not support files downloading. Please try again in desktop browser.');
    return false;
  }

  //If in Chrome or Safari - download via virtual link click
  if (isChrome || isSafari) {
    //Creating new link node.
    let link = document.createElement('a');
    link.href = sUrl;

    if (link.download !== undefined) {
      //Set HTML5 download attribute. This will prevent file from opening if supported.
      link.download = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
      link.setAttribute("target", "_blank")
    }

    if (document.createEvent) {
      link.dispatchEvent(new MouseEvent('click'));
      return true;
    }
  }

  // Force file download (whether supported by server).
  if (sUrl.indexOf('?') === -1) {
    sUrl += '?download';
  }

  window.open(sUrl, '_self');
  return true;
}

export function makeOutputFile({
                                 brand, retailer, allSelected, ignoredUnifiedProducts, unifiedProductsIds,
                                 distributeDataTemplateId, downloadImages, search, collection, orderConfirmationBatch,
                                 onFileCreated, onError
                               }) {
  let urlBase = getOnixUrl("/")

  const postData = {
    useOrderConfirmationBatch: orderConfirmationBatch ? orderConfirmationBatch.id : null,
    allSelected: allSelected,
    ignoredUnifiedProducts: ignoredUnifiedProducts,
    unifiedProductsIds: unifiedProductsIds,
    distributeDataTemplateId: distributeDataTemplateId,
    downloadImages: downloadImages,
    filter: {
      name: search,
      collection: collection,
    }
  }

  if (brand) {
    postData.brand = brand
  } else {
    postData.retailer = retailer
  }

  superagent
    .post(urlBase + 'generateDistributeDataOutput/')
    .timeout({
      response: 300000,  // Wait 5 minutes for the server to start sending,
      deadline: 400000, // but allow 1 minute for the file to finish loading.
    })
    .send(postData)
    .set('Authorization', "JWT " + localStorage.getItem('jwtKey'))
    .set('Accept', 'application/json')
    .then(response => {
      if (response) {
        onFileCreated(response.body.generateDistributeDataOutput.instance);
      } else {
        onError(response.body)
        ourToast('error', 'Oops something went wrong!');
      }
    })
    .catch((err) => {
      onError('' + err);
      ourToast('error', 'Oops something went wrong!');
    });
}


export function packOrderConfirmationFiles(files) {
  let fileFields = {}
  for (let orderConfirmName of Object.keys(files)) {
    for (let index = 0; index < files[orderConfirmName].length; index++) {
      fileFields[orderConfirmName + '_' + index] = files[orderConfirmName][index];
    }
  }
  return fileFields
}
